import { Box, Button, Paper, Typography } from "@mui/material"

const Company = () => {

	return (
		<Box>
			<Paper width="100%" elevation={1} sx={{padding:1}}>
				<Typography>Air Products</Typography>
				<Typography>Chemicals</Typography>
				<Typography>Market Cap.: $56bi</Typography>
			</Paper>
			<Typography>Análise</Typography>
			<Paper width="100%" elevation={1} sx={{padding:1}}>
				Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce non posuere felis, at ullamcorper massa. Phasellus ligula lorem, molestie sed lectus nec, sollicitudin rutrum justo. Sed vitae varius magna. Morbi fringilla sollicitudin interdum. Morbi tortor dui, tristique eget nisi at, aliquet venenatis mi. Phasellus fringilla egestas nunc at varius. Fusce eget aliquet augue. Ut nec vehicula arcu. Quisque laoreet nulla eget feugiat rhoncus. Fusce congue urna sit amet lobortis tempor. Vestibulum et lectus vel felis malesuada facilisis eu in velit. Quisque tincidunt tellus non porta porta. Vestibulum ultricies consequat dignissim. Nunc fermentum ut orci at venenatis. Sed sit amet nibh libero.
				Duis non hendrerit quam. Ut ultrices nec mi nec gravida. Fusce pretium condimentum diam, et pharetra urna mollis vel. Sed lectus massa, pellentesque eu efficitur a, volutpat in est. Pellentesque eleifend sodales risus vitae porta. Nunc hendrerit turpis luctus metus imperdiet, vitae finibus purus ultrices. Sed a sodales purus, ut tristique mauris. Maecenas congue ultrices rutrum. Donec ornare auctor sodales. Cras non libero neque. Fusce ac iaculis nunc. Aenean dolor risus, aliquet eu laoreet quis, pulvinar quis arcu.
				Nullam ultrices malesuada eleifend. Ut nisi neque, porttitor quis tempus et, laoreet non metus. Duis in blandit nunc. Cras eu eleifend tellus. Curabitur dapibus commodo nibh non pulvinar. Quisque malesuada purus ac odio ultrices ultrices. Fusce cursus est ut quam mollis, id auctor erat faucibus. Suspendisse id nibh vel metus feugiat egestas. Vestibulum quis nisi nulla. Curabitur semper quam aliquam urna gravida, venenatis tempor dui posuere. Nunc hendrerit magna nulla, et blandit lacus commodo ut. Donec ac ante quis odio imperdiet porta. In consequat urna non nunc ornare viverra.
				In quis urna in metus laoreet rutrum vitae ut quam. Etiam dictum, felis sed pulvinar ullamcorper, dolor nulla fermentum lectus, ac bibendum sapien purus faucibus metus. Curabitur at erat dui. Phasellus scelerisque dui sit amet quam sodales tempus. Nam interdum sed quam in ultrices. Ut non rhoncus odio, quis vestibulum dui. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Sed tempor eu tortor nec ullamcorper.
				Curabitur vitae sem in justo faucibus commodo quis sed ligula. Duis sit amet tellus nibh. Vestibulum elementum tellus vel mauris lobortis, ac ultrices diam eleifend. Vivamus quis risus nulla. Morbi scelerisque lectus non lorem sollicitudin, quis aliquet mi viverra. Phasellus rhoncus in metus ac lacinia. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Donec placerat tellus eget erat fringilla tempus. Donec dictum leo nec aliquet sodales. Cras fringilla varius venenatis. Nullam vestibulum accumsan mollis. Praesent a purus elit.
			</Paper>
			<Button variant="contained" >Teste</Button>
			<Button variant="contained" color="secondary" >Teste</Button>
		</Box>
	)
}

export default Company