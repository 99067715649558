import { Box, Grid, Typography } from "@mui/material"
import AssetCard from "../components/AssetCard"

const Main = () => {

	const companies = [
		{name:"Air Products",ticker:"APD", sector:"Chemicals", marketCap:"$56,27 bi", videoUrl:"tecNAT74Ttc"},
		{name:"Intel", ticker:"INTC", sector:"Semiconductors", marketCap:"$149,52 bi", videoUrl:"6EzIpz0K4p8"},
		{name:"Biogen", ticker:"BIIB", sector:"Drug Manufacturers - General", marketCap:"$32,6 bi", videoUrl:"76BStTYBa2I"},
		{name:"Ambev", ticker:"ABEV3.SA", sector:"Cervejas e Refrigerantes", marketCap:"$33,25 bi", videoUrl:"QdMFrlI-oFo"}
	]

	return (
		<Box>
			<Typography fontWeight={'bold'}>Últimas Análises</Typography>
			<Grid container spacing={2} paddingTop={1}>
				{ companies.map(company => 
					<Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
						<AssetCard 
							name={company.name}
							ticker={company.ticker}
							sector={company.sector}
							marketCap={company.marketCap}
							videoUrl={company.videoUrl}
						/>
					</Grid>
				)}
			</Grid>
		</Box>
	)

}

export default Main