import { Routes, Route } from 'react-router-dom';
import { Box, Container, CssBaseline, ThemeProvider } from '@mui/material';

import TopBar from './components/TopBar';
import Main from './views/Main';
import Company from './views/Company';

import theme from './theme';
import Footer from './components/Footer';

function App() {
	return (
		<ThemeProvider theme={theme}>
			<CssBaseline/>
			<Container maxWidth="false" sx={{margin: 0, padding: 0, paddingBottom: 1, minHeight:'calc(100vh - 150px)', backgroundColor:"#EBEBEB"}}>
				<TopBar/>
				<Box sx={{marginTop:'75px', paddingTop: 4}}>
					<Routes>
						<Route path="/" element={<Main/>}></Route>
						<Route path="/company/:ticker" element={<Company/>}></Route>
					</Routes>
				</Box>
			</Container>
			<Footer></Footer>

		</ThemeProvider>
	);
}

export default App;
