import { AppBar, Toolbar } from '@mui/material'
import logo from '../resources/images/logo_horizontal_001.png'

const TopBar = () => { 
	return (	
		<AppBar sx={{height: 75}}>
			<Toolbar sx={{display: 'flex', height: '100%', alignContent: 'center'}}>
				<img src={logo} width={180} height={60} alt='Logo'/>
			</Toolbar>
		</AppBar>
	)
}

export default TopBar