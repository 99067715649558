import { createTheme } from '@mui/material/styles';

const theme = createTheme({
	palette: {
		mode: 'light',
		primary: {
		main: '#8C57FF',
		},
		secondary: {
		main: '#c542da',
		},
		text: {
			primary: '#3f51b5',
			secondary: 'rgb(44, 56, 126)',
			disabled: 'rgb(101, 115, 195)',
			hint: '#3f51b5',
		},
		error: {
		main: '#ff0000',
		},
		success: {
		main: '#2e7d32',
		},
	},
	typography: {
		fontWeightRegular: 500,
		fontFamily: 'Mulish',
	},
});

export default theme