import { Box, Card, CardActions, CardContent, CardMedia, IconButton, Typography } from "@mui/material"
import { Link } from "react-router-dom";

import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import YouTubeIcon from '@mui/icons-material/YouTube';

const AssetCard = (props) => {

	let cardImage;
	
	try {
		cardImage = require('../resources/images/'+ props.ticker.toLowerCase() +'_card.png')
	}
	catch(e){
		cardImage = require('../resources/images/generic_card.png')
	}

	let reportFile; 

	try { 
		reportFile = require('../resources/reports/'+ props.ticker.toLowerCase() +'_report.pdf')
	}
	catch(e) {
		// do nothing
	}

	return (
		<Card sx={{minWidth:300, borderRadius: '20px'}}>
			<CardContent>
				<Box sx={{display: 'flex', alignItems: 'center'}}>
					<Typography variant="body1" flexGrow={1}>{props.name} ({props.ticker})</Typography>
					<Box sx={{backgroundColor:'lightgrey', borderRadius:'25%', height: 40, width: 40, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
						<img src={"https://financialmodelingprep.com/image-stock/"+props.ticker+".png"} alt='Imagem Ilustrativa da Companhia' height={24} width={24}/>
					</Box>
				</Box>
				<Typography variant="caption">{props.sector}</Typography>
			</CardContent>

			<CardMedia image={cardImage} sx={{height:150}}></CardMedia>

			<CardContent>
				<Typography variant="body2">Market Cap. {props.marketCap}</Typography>
			</CardContent>
			
			<CardActions sx={{display: 'flex', alignItems: 'center', justifyContent:'right'}}>
				{(reportFile ? 
				<IconButton>
					<Link to={reportFile} target="_blank">
						<PictureAsPdfIcon sx={{color:'darkred'}}/>
					</Link>
				</IconButton>
				: null )}
				<IconButton sx={{margin: '0 !important'}} >
					<Link to={'https://www.youtube.com/watch?v='+ props.videoUrl} target="_blank" rel="noopener noreferrer">
						<YouTubeIcon sx={{color:'red'}}/>
					</Link>
				</IconButton>
			</CardActions>
		</Card>	
	)
} 

export default AssetCard