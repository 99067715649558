import { Box, IconButton, Stack, Typography } from "@mui/material"
import { Link } from "react-router-dom"

const Footer = () => {
	return (
		<Box sx={{display: 'flex', width:'100%', backgroundColor: 'primary.light', height:75, justifyContent: 'center' }}>
			<Stack display={'flex'} direction={'column'} spacing={1} alignItems={'center'} justifyContent={'center'}>
				<Typography variant='body2' sx={{paddingTop: 1, color:'#2C387E'}}>Analisando Empresas - 2024</Typography>
				<Stack direction={'row'}>

					{/* <!-- Instagram --> */}
					<Link to="https://www.instagram.com/analisandoempresas/" target="_blank">
						<IconButton sx={{width:32, height:32}}>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								fill="currentColor"
								style={{ color: "#2C387E" }}
								viewBox="0 0 24 24"
								>
								<path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
							</svg>
						</IconButton>
					</Link>

					{/* <!-- Facebook --> */}
					<Link to="https://www.facebook.com/analisandoempresas/" target="_blank">
						<IconButton sx={{width:32, height:32}}>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								className="h-7 w-7"
								fill="currentColor"
								style={{ color: "#2C387E" }}
								viewBox="0 0 24 24"
								>
								<path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
							</svg>
						</IconButton>
					</Link>

					{/* <!-- You Tube --> */}
					<Link to="https://www.youtube.com/channel/UC0zLG0ZmuRYBGm0ddGjkHlQ" target="_blank">
						<IconButton sx={{width:32, height:32}}>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								className="h-7 w-7"
								fill="currentColor"
								style={{ color: "#2C387E" }}
								viewBox="0 0 24 24"
								>
								<path d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z" />
							</svg>
						</IconButton>
					</Link>

					{/* <!-- TikTok --> */}
					{/* <IconButton sx={{width:32, height:32}}>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 498 552"
						style={{ color: "#2C387E" }}
						>
						<path
							fill="currentColor"
							d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z"
						/>
					</svg>	  
					</IconButton> */}

					{/* <!-- X --> */}
					<Link to="https://x.com/analisandoemp" target="_blank">
						<IconButton sx={{width:32, height:32}}>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 498 552"
							style={{ color: "#2C387E" }}
							>
							<path 
								fill="currentColor"
								d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/>
						</svg>
						</IconButton>
					</Link>

				</Stack>
			</Stack>
		</Box>
	)
}

export default Footer